.view_agriculture_heading {
  font-family: "Arial" !important;
  font-weight: 700 !important;
  font-size: 26px !important;
  line-height: 36.06px !important;
  color: #3d4a52 !important;
}

.view_datasets_light_text {
  font-family: "Arial" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.view_datasets_bold_text {
  font-family: "Arial" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.wordWrap {
  word-wrap: break-word;
}

.view_datasets_bold_text_sm {
  font-family: "Arial" !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  color: #000000 !important;
  max-width: 200px;
  word-wrap: break-word;
}

.ml-134 {
  margin-left: 134px !important;
}

.mt-38 {
  margin-top: 38px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.overflow_x_scroll {
  overflow-x: auto;
}

.w-313 {
  width: 313px;
}

.ml-79 {
  margin-left: 79px;
}

.ml-28 {
  margin-left: 28px;
}

.ml-39 {
  margin-left: 39px;
}

.organisation_icon_card {
  width: 172px !important;
  height: 172px !important;
  border: 1px solid #f2f3f5 !important;
  box-shadow: -40px 40px 80px rgba(145, 158, 171, 0.16) !important;
  border-radius: 16px !important;
}

.type_dataset {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #585454;
  font-family: "Public Sans";
  background: rgba(0, 184, 217, 0.16);
  width: 124px;
  height: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  border-radius: 6px;
  margin-left: 30px;
  text-transform: uppercase;
}
.break_word {
  word-break: break-all;
}
.ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.maxWidth400 {
  max-width: 400px;
}
