.footer {
    text-align: center;
    background-color: #C09507;
    /*position: fixed;*/
    bottom: 0px;
    min-width: 1440px;
    min-height: 80px;
    vertical-align: middle;
    padding-top: 30px;
    margin: 0px;
}

.footerlink {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    /*margin-left: 100px;*/
}

.footerlink:hover {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.footerlink .footerlinkdisabled {
    pointer-events: none;
}

.footer .row {
    margin: 0px !important;
}