.title {
  font-family: "Arial", sans-serif;
  font-weight: 300;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  color: #585d60;
}

.description {
  font-family: "Arial", sans-serif;
  font-weight: 400 !important;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  font-style: italic !important;
  color: #565c63 !important;
  margin-top: 32px;
}

.descriptionSm {
  font-family: "Arial", sans-serif;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  font-style: italic !important;
  color: #565c63 !important;
  margin-top: 32px;
}

.titleContainer {
  justify-content: center;
}

.title2 {
  margin: 50px 0;
}

.backButton {
  /* margin-top: 50px !important;
      margin-bottom: 50px !important; */
  margin: 0 15px !important;
  font-size: 15px !important;
}

.backButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  padding: 50px 0 0 0;
}

.backButtonContainerAlingCenter {
  padding: 50px 0;
}

.backButtonContainerAlingCenter > button {
  margin: auto !important;
  width: 350px;
}

.borderNone {
  border: none !important;
  margin: 0 15px !important;
}

.primary_button {
  margin: 0 15px !important;
  padding: 10px 35px !important;
  font-size: 15px !important;
}

.radioButtonContainer {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.radioButtonContainerSm {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.adminDetailsContainer {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.adminDetailsCol {
  margin: 15px 0;
}
