.datasetfiltertext{
width: 35px;
height: 19px;
left: 193px;
top: 136px;

margin-left: 5px;
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 19px;
/* identical to box height */

align-items: center;

color: #3A3A3A;
}
.filterClearAll{

font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
/* identical to box height */
display: flex;
align-items: center;
text-decoration-line: underline;

color: #3491EE;
}