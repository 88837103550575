.loding-img-containe{
    width: 100%;
    height: 80%;
}

.loding-img-div{
    margin: 15% auto;
}
.loding-img-div > img{
    height: 150px;
}
.loding-img-div > h1{
    color: #050504cc;
    font-size: 22px;
    margin-top: 40px;
    font-weight: 400;
}
.loding-img-div > h3{
    color: #050504cc;
    font-size: 18px;
    /* margin-top: 40px; */
    font-weight: 400;
}