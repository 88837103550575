#admin_add_dataset_main_container .Mui-selected {
  background-color: #c09507 !important;
  color: white !important;
  text-transform: none;
}

.dataset_name_class {
  width: 80%;
}

.main_heading_row {
  text-align: left;
}

.Main_heading_add_dataset {
  font-weight: 600;
  font-size: 24px;
}

.connect_btn {
  background-color: white !important;
  color: black !important;
  border: 1px solid #c09507 !important;
  width: 80%;
  text-transform: capitalize;
  margin: 10px 0px;
}

.connect_btn:hover {
  background-color: #c09507 !important;
  color: white !important;
  border: 1px solid #c09507;
}

.textfield_row {
  text-align: left !important;
}

.database_img {
  transition: all 2s;
}

.paperForTotal {
  background-color: #c09507 !important;
  color: white !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  /* padding: 10px; */
  display: block !important;
  /* height: 80px !important; */
  border: 1px solid;
}

.statusInsidePaper {
  /* background-color: #c09507 !important; */
  color: white !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* border-radius: 4px; */
  /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%); */
  /* padding: 10px; */
  /* display: block !important; */
  /* height: 40px !important; */
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.disconnect_btn {
  background-color: white !important;
  color: red !important;
  width: 80%;
  text-transform: capitalize;
  margin: 10px 0px;
  border: 1px solid red !important;
  outline: none !important;
}

.disconnect_btn:hover {
  color: white !important;
  background-color: red !important;
}

.green_btn_for_connect {
  border: 1px solid #00a94f !important;
  color: #00a94f !important;
  margin: 0px auto !important;
  display: block !important;
}

.green_btn_for_connect:hover {
  border: 1px solid #00a94f !important;
  color: white !important;
  background-color: #00a94f !important;
}

.MuiSvgIcon-root {
  /* background-color: #c09507 !important; */
  color: #00a94f !important;
}

.MuiStepConnector-horizontal.Mui-completed,
.MuiStepConnector-horizontal.Mui-active,
.MuiStepConnector-horizontal.Mui-active span,
.MuiStepConnector-horizontal.Mui-completed span {
  border: 1px solid #00a94f !important;
  background-color: #00a94f !important;
}

/* .Mui-completed {
    font-weight: 600 !important;
    font-size: 20px !important;
} */
.button_main_box button {
  width: 20%;
  text-transform: capitalize !important;
}

.button_main_box .next_btn {
  color: #c09507 !important;
  border: 1px solid #c09507 !important;
}

.button_main_box .next_btn:hover {
  color: white !important;
  background-color: #c09507 !important;
}

.button_main_box .next_btn:disabled {
  color: rgba(128, 128, 128, 0.78) !important;
  background-color: rgba(128, 128, 128, 0.58) !important;
  border-color: grey !important;
}

.button_main_box .cancel_btn {
  color: red !important;
  border: 1px solid red !important;
}

.button_main_box .cancel_btn:hover {
  color: white !important;
  background-color: red !important;
}

.button_main_box .back_btn {
  color: #c09507 !important;
}

.button_main_box .back_btn:hover {
  color: #c09507 !important;
  background-color: unset !important;
}

#admin_add_dataset_main_container .submit_btn {
  width: 400px !important;
  color: #c09507 !important;
  border: 1px solid #c09507 !important;
  text-transform: capitalize !important;
  margin-top: 20px;
}

#admin_add_dataset_main_container .submit_btn:hover {
  color: white !important;
  background-color: #c09507 !important;
}

#admin_add_dataset_main_container .submit_btn:disabled {
  color: rgba(255, 255, 255, 0.45) !important;
  background-color: grey !important;
  border-color: grey !important;
  width: 400px !important;
  text-transform: capitalize !important;
}
