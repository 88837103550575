.data_standardization_cloumn_container{
    display: flex;
    justify-content: space-around;
    /* padding-right: 200px; */
}
.data_standardization_title{
    display: flex;
    justify-content: space-around;
    /* padding-right: 300px; */
    margin-top: 30px;
    margin-bottom: 30px;
    /* position: fixed;
  top: 0; */
}
.data_standardization_title div > span{
    font-weight: 700;
    height: 50px;
    font-size: 18px;
}
.uploaded_data_column_name_title{
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    max-width: 150px;
    max-width: 300px;
    /* min-width: 100px; */
    word-wrap: break-word;
}
.uploaded_data_column_name_title_container{
    display: flex;
    align-items: center;
    /* word-wrap: break-word; */
}
.data_standardization_title:nth-child(1) span{
    display: inline-block;
    text-align: left;
}

.uploaded-data-column-names{
    display: inline-flex;
    /* border: 1px solid red !important; */
}
.data-standardization-in-add-dataset-container{
    /* max-height: 450px;
    overflow: scroll;
    position: relative; */
}

.data_standardization_column{
    max-height: 450px;
    overflow: scroll;
    position: relative;
    overflow-x: hidden;
}
.standardization-button-container{
    display: flex;
    flex-direction: row-reverse;
    padding-right: 30px;
}