.accountsettingsupload {
  background: #ffffff;
  border: 1px dashed #d8af28;
  border-radius: 2px;
  margin-top: 8%;
  margin-left: 27%;
  width: 240%;
  height: 218px;
}

.accountsettingsheader {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #3d4a52;
  padding-top: 2%;
}
