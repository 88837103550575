.signoutbtn-navbar {
  background: #ffffff !important;
  padding: 5px 15px !important;
  color: #d8af28 !important;
  outline: none !important;
  border: 1px solid #d8af28 !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
  /* text-decoration: none !important; */
  /* Second Nav */
  text-transform: none !important;
  /* margin-left: 24px !important; */
  font-family: "Open Sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  border-radius: 2px;
}

.navtext{
  vertical-align: middle;
  margin-top: 24px;
  margin-left: 0px;
  color: black;
}

.navlink{
  color: #808080;
display: flex;
align-items: center;
text-decoration: none;
margin-left: 29px;
/*margin-bottom: -5px;*/
/*margin-top: 25px;*/
margin-right: 29px;
padding: 0px;
height: 100%;
cursor: pointer;

font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 138.69%;
/* or 19px */

/* headings */
color: #A3B0B8;
}
.navlink.active {
	border-bottom: 8px solid #c09507;
	margin-bottom: 5px;
	margin-top: 10px;
	color: #3D4A52;

}
.navlink.active > .boldimage {
	display: block;
}
.navlink.active > .nonboldimage {
	display: none;
}
.navlink:hover {
    text-decoration: none;
    color: #A3B0B8;
}
.navlink.active:hover {
	color: #3D4A52;
}
.navbar_col{
  display: flex;
  position: relative;
  justify-content:flex-start;
}
.navbar_right_col{
  display: flex;
  width: 700px;
  justify-content:flex-end; 
  flex-direction: row-reverse;
   
}