/* @import url("https://fonts.googleapis.com/css2?family=Arial:wght@300;400;600;700&display=swap"); */

.title {
  font-family: "Arial", sans-serif;
  font-weight: 300;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  color: #585d60;
}

.title_sm {
  font-family: "Arial", sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 78px;
  text-align: center;
  color: #585d60;
  margin-top: 20px;
}

.title_md {
  font-family: "Arial", sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 78px;
  text-align: center;
  color: #585d60;
  margin-top: 50px;
}

.description {
  font-family: "Arial", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
  width: 800px;
}

.description_sm {
  font-family: "Arial", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  width: 800px;
}

.input_field {
  margin-top: 40px !important;
  width: 564px;
  height: 44px;
  border: 1px solid #919eab;
}

.input_field_md {
  margin-top: 40px !important;
  width: 464px;
  height: 44px;
  border: 1px solid #919eab;
}

.input_field_sm {
  margin-top: 40px !important;
  width: 315px;
  height: 31px;
  border: 1px solid #919eab;
}

.input_field .MuiOutlinedInput-root {
  border-radius: 30px;
}

.filter {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 6px;
}

.filter_md {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 6px;
}

.filter_sm {
  margin-top: 50px;
  margin-bottom: 6px;
}

.filter_slg {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 6px;
}

.filter_text {
  margin-left: 12.34px;
  cursor: pointer;
}

.filter_text_md {
  display: flex;
  margin-left: 5.34px;
  cursor: pointer;
}

.ft-12 {
  font-size: 12px;
}

.filter_text_container {
  padding: 5px;
}

.filter_text_container:hover {
  background: #e4e6e8;
  border-radius: 16px;
  cursor: pointer;
}

.filter_text_container_active {
  background: #e4e6e8;
  border-radius: 16px;
  cursor: pointer;
}

.bold_title {
  font-family: "Arial";
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin-top: 50px;
  text-align: left;
  margin-bottom: 20px;
}
.bold_title_main {
  font-family: "Arial";
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 20px;
}

.add_new_dataset {
  font-family: "Arial";
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  text-align: center;
  margin: 21px 0px 21px 0px;
}

.add_new_dataset_text {
  margin-top: 0px;
  font-family: "Arial";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  padding: 10px;
}

.card:hover {
  box-shadow: -40px 40px 80px rgba(145, 158, 171, 0.16);
  cursor: pointer;
}

.published {
  text-align: left;
  margin: 21px 0px 0px 20px !important;
}

.published_text {
  /* font-family: 'Avenir'; */
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #9ba0a7;
  margin-left: 7px;
}

.d_content_title {
  font-family: "Arial" !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 30px !important;
  color: #00a94f !important;
  text-align: left !important;
  margin: 0px 0px 0px 20px !important;
  white-space: nowrap;
  width: 279px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.organisation {
  text-align: left;
  margin: 5px 0px 0px 20px !important;
  display: flex;
  align-items: center;
}

.organisation_text {
  font-family: "Arial";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-left: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  text-align: left;
}

.d_content_text {
  display: flex;
  margin: 20px 20px 20px 20px !important;
}

.category {
  display: flex;
  align-items: center;
  max-width: 150px;
}

.location {
  margin-left: 15px;
  display: flex;
  align-items: center;
  max-width: 150px;
}

.calendar {
  margin-left: 23px;
  display: flex;
  align-items: center;
}

.category_text {
  margin-left: 7px;
  /* font-family: 'Avenir'; */
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location_text {
  margin-left: 7px;
  /* font-family: 'Avenir'; */
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar_text {
  margin-left: 7px;
  /* font-family: 'Avenir'; */
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

.datasets_card {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.d_button_style {
  font-family: "Arial" !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  margin-top: 50px !important;
  width: 368px !important;
  height: 48px !important;
  border-radius: 8px !important;
  /* background: #00A94F !important; */
  border: 1px solid rgba(0, 171, 85, 0.48) !important;
  color: #00a94f !important;
}

.d_button_style_md {
  font-family: "Arial" !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  margin-top: 50px !important;
  width: 162px !important;
  height: 36px !important;
  border-radius: 8px !important;
  /* background: #00A94F !important; */
  border: 1px solid rgba(0, 171, 85, 0.48) !important;
  color: #00a94f !important;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .datasets_card {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .category_text {
    font-size: 14px;
  }

  .location_text {
    font-size: 14px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .datasets_card {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 22px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .datasets_card {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 22px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .datasets_card {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 22px;
  }
}

@media only screen and (min-width: 1650px) {
  .datasets_card {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 18px;
  }
}
