/* CSS */
.button-87 {
    /* margin: 10px; */
    padding: 10px 30px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: #534104;
    border-radius: 10px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #d69437e9;
    background-image: linear-gradient(45deg, #FFDB64 0%, #d69437e9 51%, #FFDB64 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    text-transform: capitalize;
    width: 200px;
    height: 50px;
    font-size: 16px;
}

.button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.button-87:active {
    transform: scale(0.95);
}