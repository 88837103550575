.projectform .connectormainheading {
  color: #3d4a52;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin-top: 8% !important;
  text-align: start;
  margin-left: 6%;
}
.projectform .supportViewDetailsbackimage {
  margin-left: 14px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Open Sans";
  color: #3491ee;
  margin-top: 0px;
  margin-left: 7%;
}
.projectform .department {
  padding-left: 8%;
  margin-top: 2%;
  text-align: left;
}
.projectform .project {
  /* margin-top: 2%; */
  text-align: left;
  margin-top: 2.7%;
}
.projectform .projectName {
  width: 80%;
}

.projectform .description {
  float: left;
  /* width: 80% !important; */
  margin-top: 2%;
  /* padding-left: 2% !important; */
  text-align: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 7%;
}
.projectform .descriptionName {
  width: 83.5% !important;
}
