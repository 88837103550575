.mr-16 {
  margin-right: 16px !important;
}

.mr-120 {
  margin-right: 120px !important;
}

.ml-173 {
  margin-left: 173px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}
.datapoint-category-classname > div:nth-child(1) {
  cursor: default !important;
}
