@import "../../Constants/CssConstants.css";
.guestdiv .supportmaindiv {
  margin-top: 0px;
  margin-left: 110px;
  margin-right: 110px;
}
.guestdiv .supportfilterRow {
  margin-top: 32px;
  width: 1080px;
}
.guestdiv .supportfilterCOlumn {
  height: "fit-content";
  max-width: 346px;
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 2px;
  flex-basis: 346px;
}
.guestdiv .supportfilterfirstrow {
  height: 50px;
  background: #f4f4f4;
}
.guestdiv .supportfiltertext {
  padding-top: 16px;
  padding-left: 13px;
}
/* .supportfilterclearall{padding-top:16px;padding-left:13px} */
.guestdiv .supportfilterheadingtext {
  padding-top: 18px;
  padding-left: 15px;
}
.guestdiv .supportfiltersecondrow {
  height: 54px;
  background: var(--theme-color);
}
.guestdiv .supportfiltersecondrowbold {
  height: 54px;
  background: #ffffff;
  border: 1px solid #f7f7f7;
}
.guestdiv .supportallicon {
  padding-left: 13px;
  padding-top: 16px;
}
.guestdiv .supportalltexticon {
  padding-left: 13px;
  padding-top: 19px;
}
.guestdiv .supportfiltercommorrow {
  height: 54px;
  background: var(--theme-color);
}
.guestdiv .supportfiltercommontexticon {
  padding-left: 31px;
  padding-top: 16.5px;
}
.guestdiv .supportfiltercommontext {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  padding-left: 11px;
  padding-top: 20px;
}
.guestdiv .supportfiltercommorrowbold {
  height: 54px;
  background: #ffffff;
  border: 1px solid #f7f7f7;
}
.guestdiv .supportfiltercommontexticonbold {
  padding-left: 31px;
  padding-top: 16.5px;
}
.guestdiv .supportfiltercommontextbold {
  font-weight: 400;
  font-size: 14px;
  color: #3d4a52;
  padding-left: 11px;
  padding-top: 20px;
}
.guestdiv .supportSecondCOlumn {
  width: 732px;
  margin-left: 20px;
}
.guestdiv .supportcardfirstcolumn {
  margin-top: 20px;
  text-align: left;
}
.guestdiv .supportcardsecondcolumn {
  text-align: right;
  margin-right: 10px;
  margin-top: 20px;
}
.guestdiv .supportcardsecondcolumndata {
  text-align: right;
  margin-right: 20px;
  margin-top: 20px;
}
.guestdiv .supportcardmargintop {
  margin-top: -19px;
}
.guestdiv .supportcardmargintop20px {
  margin-top: 20px;
}
.guestdiv .supportcardmargintop0px {
  margin-top: 0px;
}
.guestdiv .supportcard .MuiCardHeader-title {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #3d4a52;
}
.guestdiv .supportfilterthirdrow {
  height: 171px;
  background: #ffffff;
}
.guestdiv .supportfilterthirdrowhighlight {
  height: 171px;
  background: #fff7dc;
}
.guestdiv .supportfilterthirdrowheadingtext {
  padding-top: 18px;
  padding-left: 13px;
}
.guestdiv .supportcardfromdate {
  margin-top: 54px;
  margin-left: -51px;
  width: 150px;
}
.guestdiv .supportcardtodate {
  margin-top: 54px;
  margin-left: 20px;
  width: 150px;
}
.guestdiv .supportsubmitbrn .btncolor {
  color: var(--theme-color);
  border-color: var(--theme-color);
  border-radius: 0;
  text-transform: capitalize;
  background: #ffffff;
  border: 1px solid #d8af28;
  border-radius: 2px;
}
.guestdiv .supportsubmitbrn {
  margin-left: 253px;
}
.guestdiv .marginleft165px {
  margin-left: 165px;
}
.guestdiv .disbaledatesubmitbtn {
  text-transform: capitalize !important;
  border-radius: 2px;
  background: #ffffff !important;
  border: 1px solid #c09507 !important;
  color: #585d60 !important;
  opacity: 0.5;
}
.guestdiv button.enabledatesubmitbtn {
  text-transform: capitalize !important;
  color: #ffffff;
  background: #c09507 !important;
  border-radius: 2px;
  border: 2px solid #c09507 !important;
}
.guestdiv button.enabledatesubmitbtn:hover {
  background-color: #c09507 !important;
}
.guestdiv .supportcardsecondclosedcolumndata {
  text-align: right;
  margin-right: 9px;
  margin-top: 20px;
}
.guestdiv .supportcardfromdate .MuiTextField-root {
  background: #f1f1f1;
}
.guestdiv .supportcardtodate .MuiTextField-root {
  background: #f1f1f1;
}
.guestdiv .supportfirstmaindiv {
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 50px;
  background: #fcfcfc;
}
.guestdiv .supportViewDetailsback {
  margin-left: 14px;
  font-weight: 400;
  font-size: 14px;
  color: #3491ee;
}
.guestdiv .supportViewDetailsbackimage {
  margin-left: 94px;
  margin-top: 54px;
  text-align: left;
  cursor: pointer;
}
.guestdiv .supportViewDeatilsSecondRow {
  border: 1px solid rgba(238, 238, 238, 0.5);
  margin-top: 23px;
}
.guestdiv .oversizemb-uploadimglogo {
  width: 100%;
  color: red;
}
.guestdiv .marginrowtop8px {
  margin-top: 8px;
}
.guestdiv .resolution textarea {
  border: none;
  border-bottom: 1px solid black;
  background: #f1f1f1;
  border-radius: 2px;
}
.guestdiv .messagedescription {
  height: 117px;
  background: #ffffff;
  border: 1px solid #f9eabc;
  border-radius: 2px;
  overflow: scroll;
  width: 306px;
  padding: 10px;
}
.guestdiv .nodataavailable {
  margin-left: 50%;
  margin-top: 10%;
}
.guestdiv .requestChange {
  font-weight: 400;
  font-size: 14px;
  color: #c4c4c4;
}
