.datasets_list_view_title {
  font-family: "Arial" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.datasets_list_view_text {
  font-family: "Arial" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #000000 !important;
}

.datasets_list_view_name {
  white-space: nowrap;
  width: 183px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.datasets_list_view_details_ellipsis {
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mr-44 {
  margin-right: 44px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.green_text {
  color: #00a94f !important;
}
.mt114 {
  margin-top: 114px;
}

.mt10 {
  margin-top: 10px !important;
}

.mb139 {
  margin-bottom: 139px;
}
