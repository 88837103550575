.datasetform {
  overflow-x: hidden;
}

.datasetform .AddDatasetmainheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}

.datasetform .name {
  width: 100%;
  margin-top: 5%;
  /*margin-left: 40%;*/
}

.datasetform .description {
  float: left;
  width: 100% !important;
  margin-top: 5%;
  /*margin-left: 3%;*/
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.datasetform .checkbox1 {
  width: 100%;
  margin-left: 0px;
  margin-top: 1%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* headings */

  color: #3d4a52;
}

.datasetform .AddDatasetsecondaryheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}

.datasetform .checkbox2 {
  width: 100%;
  /*margin-left: -40px;*/
  margin-top: 1%;
  font-family: "Open Sans";
  float: left;
}

.datasetform .checkbox2 .soil {
  /*float: left;*/
  font-family: "Open Sans";
}

.datasetform .checkbox2 .weather {
  /*float: left;*/
  font-family: "Open Sans";
  /*margin-left: -18px;*/
}

.datasetform .crop {
  width: 100% !important;
  margin-top: 10%;
  /*margin-left: 3%;*/
  float: left;
}

.datasetform .AddDatasetageheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  /*margin-left: 41%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}

.datasetform .constantswitch {
  margin-top: 6%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  margin-right: -10px;
  float: right;
}

.datasetform .radiobtns {
  /*margin-left: 20%;*/
}

.datasetform .radiobtns .sixmonth {
  margin-left: 13%;
}

.datasetform .radiobtns .ninemonth {
  margin-left: 13%;
}

.datasetform .radiobtns .twelvemonth {
  margin-left: 13%;
}

.datasetform .FromDate {
  /*padding-left: 21%;
    padding-top: 2%;*/
}

.datasetform .uploadfiles {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
  text-align: left;
}

.datasetform .toDate {
  /*padding-top: 2%;
    /* float: left; */
}

.datasetform .FromDate .fromtextfield {
  width: 100%;
}

.datasetform .toDate .totextfield {
  float: left;
  width: 100%;
  /* padding-left: 2%; */
  /*margin-left: 3%;*/
}

.datasetform .recordradiobtns {
  /*margin-left: 20%;*/
  /*margin-top: 1%;*/
}

.datasetform .recordradiobtns .record2 {
  margin-left: 16.5%;
}

.datasetform .recordradiobtns .record3 {
  margin-left: 16.5%;
}

.datasetform .recordradiobtns .record4 {
  margin-left: 16.5%;
}

.datasetform .notavaiable {
  margin-left: 13%;
}

.datasetform .private {
  margin-left: 15%
}

.datasetupload {
  background: #ffffff;
  border: 1px dashed #d8af28;
  border-radius: 2px;
  margin-top: 0;
  /*margin-left: 35%;*/
  /*width: 220%;*/
  height: 218px;
  align-items: center;
}

.uploaddatasetname {
  text-align: left;
  width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3d4a52;
  float: left;
}

.fileUpload {
  width: 100% !important;
  float: left !important;
}

.fileUpload.is-disabled {
  border: none !important;
}

.datasetFormTab {
  width: 732px;
  margin-left: -15px;
}

.headSpace {
  /* padding-bottom: 10px; */
  margin-top: 200x;
  padding-top: -20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  text-align: left;
  margin-bottom: 30px;
}

.uploadList {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.details {
  max-width: 400px !important;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 138.69%; */
  font-family: "Open Sans";
  font-style: normal;
  color: #3d4a52;
  word-break: break-all;
  text-align: left !important;
}

.viewdetails_table table td {
  max-width: 100px;
  word-break: break-all !important;
}
.mui_snackbar_in_live_api_classname > div :nth-child(2){
   max-width: 1300px !important;
    overflow: scroll !important;
     height: 35px !important; 
}