@import url("https://fonts.googleapis.com/css2?family=Arial:wght@300;400&display=swap");

.title {
  font-family: "Arial", sans-serif;
  font-weight: 300;
  font-size: 64px;
  line-height: 78px;
  text-align: center;
  color: #585d60;
}

.description {
  font-family: "Arial", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-top: 32px;
  width: 956px;
}

.input_field {
  margin-top: 40px !important;
  width: 564px;
  height: 44px;
  border: 1px solid #919eab;
}

.input_field .MuiOutlinedInput-root {
  border-radius: 30px;
}

.bold_title {
  font-family: "Arial";
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
  margin-top: 100px;
  text-align: left;
  margin-bottom: 20px;
}

.card:hover {
  box-shadow: -40px 40px 80px rgba(145, 158, 171, 0.16);
  cursor: pointer;
}

.img_container {
  margin: 40px 53px 30px 40px;
}

.content_title {
  font-family: "Arial";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #00a94f;
  width: 287px;
  text-align: start;
  margin: 0px 41px 30px 40px !important;
}

.content_text {
  margin-left: 40px;
  margin-bottom: 40px;
}

.content_text1 {
  font-family: "Arial";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  color: #000000;
}

.content_text2 {
  font-family: "Arial";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  color: #000000;
  margin-top: 2px;
}

.participants_card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin: 0 auto;
}

.button_style {
  font-family: "Arial" !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  margin-top: 50px !important;
  width: 368px !important;
  height: 48px !important;
  border: 1px solid rgba(0, 171, 85, 0.48) !important;
  border-radius: 8px !important;
  color: #00a94f !important;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .title {
    font-weight: 300;
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    color: #585d60;
  }

  .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin-top: 32px;
    width: 700px;
  }

  .input_field {
    margin-top: 40px !important;
    width: 364px;
    height: 44px;
    border: 1px solid #919eab;
  }

  .bold_title {
    font-weight: 400;
    font-size: 22px;
    line-height: 39px;
  }

  .participants_card {
    grid-template-columns: repeat(2, 1fr);
  }

  .card {
    max-width: 100% !important;
  }

  .button_style {
    font-weight: 700 !important;
    font-size: 13px !important;
    line-height: 22px !important;
    width: 360px !important;
    height: 48px !important;
  }

  .footer_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .footer_light_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .footer_dark_text {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .link {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .button_text {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 22px !important;
  }

  .input_field_subscribe {
    width: 364px !important;
    height: 56px;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-weight: 300;
    font-size: 27px;
    line-height: 60px;
    text-align: center;
    color: #585d60;
  }

  .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin-top: 32px;
    width: 700px;
  }

  .input_field {
    margin-top: 40px !important;
    width: 364px;
    height: 44px;
    border: 1px solid #919eab;
  }

  .participants_card {
    grid-template-columns: repeat(1, 1fr);
  }

  .card {
    max-width: 100% !important;
  }

  .button_style {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 22px !important;
    width: 260px !important;
    height: 48px !important;
  }

  .footer_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  .footer_light_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }

  .footer_dark_text {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .link {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .button_text {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 22px !important;
  }

  .input_field_subscribe {
    width: 364px !important;
    height: 56px;
  }
}
