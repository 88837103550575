.container{
    margin-top: 50px;
    margin-left: 180px;
    margin-right: 180px;
}

.banner{
    height: 320px;
    width: 100%;
    min-width: 1440px;
    height: 320px;
}

.logo{
    width: 200px;
    height: 200px;

    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(216, 175, 40, 0.13);
    border-radius: 120px;

    min-width: "140px";
    min-height: "140px"; 
    max-width : "140px";
    max-height: "140px";
    margin-top: "-70px";
    text-align: center;
}