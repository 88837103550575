@import '../../Constants/CssConstants.css';
.connectors .supportmaindiv{margin-top:50px;margin-left:110px;margin-right:110px}
.connectors .supportfilterRow{margin-left: -73px; margin-top:32px;width: 1240px;}
.connectors .supportfilterCOlumn{ height:fit-content;max-width:346px;background: #FFFFFF;border: 1px solid #E4E4E4;border-radius: 2px;flex-basis:346px}
.connectors .supportfilterfirstrow{ height:50px;background:#F4F4F4}
.connectors .supportfiltertext{padding-top:16px;padding-left:13px}
.connectors .supportfilterheadingtext{padding-top:18px;padding-left:15px}
.connectors .supportfiltersecondrow{ height:54px;background:var(--theme-color);}
.connectors .supportfiltersecondrowbold{ height:54px;background:#FFFFFF;border: 1px solid #F7F7F7;}
.connectors .supportallicon{padding-left: 13px;padding-top: 16px;}
.connectors .supportalltexticon{padding-left: 13px;padding-top: 19px;}
.connectors .supportfiltercommorrow{height:54px;background:var(--theme-color)}
.connectors .supportfiltercommontexticon{padding-left: 31px;padding-top: 16.5px;}
.connectors .supportfiltercommontext{font-weight: 600;font-size: 14px;color: #FFFFFF;padding-left: 11px;padding-top:20px;}
.connectors .supportfiltercommorrowbold{height:54px;background:#FFFFFF;border: 1px solid #F7F7F7;}
.connectors .supportfiltercommontexticonbold{padding-left: 31px;padding-top: 16.5px;}
.connectors .supportfiltercommontextbold{font-weight: 400;font-size: 14px;color: #3D4A52;padding-left: 11px;padding-top:20px;}
.connectors .supportSecondCOlumn{ width:732px}
.connectors .supportcardfirstcolumn{margin-top: 20px;text-align: left;}
.connectors .supportcardfirstcolumngeo{margin-top: 20px;text-align: left; overflow: hidden; text-overflow: ellipsis; 
  display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;}
.connectors .supportcardsecondcolumn{text-align: right;margin-right: 10px;margin-top: 20px;}
.connectors .supportcardsecondcolumndata{text-align: right;margin-right: 20px;margin-top: 20px; overflow: hidden; text-overflow: ellipsis; 
  display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;}
.connectors .supportcardmargintop{margin-top: -19px;}
.connectors .supportcardmargintop20px{margin-top:20px}
.connectors .supportcardmargintop0px{margin-top:0px}
.connectors .supportcard .MuiCardHeader-title{font-weight: 600 !important;font-size: 14px !important; color: #3D4A52;}
.connectors .supportfilterthirdrow{ height:171px;background:#FFFFFF}
.connectors .supportfilterthirdrowhighlight{ height:171px;background:#FFF7DC}
.connectors .supportfilterthirdrowheadingtext{padding-top:18px;padding-left:13px}
.connectors .supportcardfromdate{margin-top: 54px;margin-left: -51px;width: 150px;}
.connectors .supportcardtodate{margin-top: 54px;margin-left:20px;width: 150px;}
.connectors .supportsubmitbrn .btncolor{color: var(--theme-color);border-color:var(--theme-color);border-radius:0;text-transform:capitalize;background: #FFFFFF; border: 1px solid #D8AF28;border-radius: 2px} 
.connectors .supportsubmitbrn{margin-left: 218px;}
.connectors .marginleft165px{margin-left: 165px;}
.connectors .disbaledatesubmitbtn{right:35px;text-transform: capitalize !important;border-radius: 2px; background: #FFFFFF !important;border:1px solid #C09507 !important;color:#585D60 !important;opacity: 0.5;  width: 116px; height: 34px; }
.connectors button.enabledatesubmitbtn{right:35px;text-transform: capitalize !important;color: #FFFFFF;height:42px; background:#C09507 !important;border-radius: 2px;border:2px solid #C09507 !important; width: 116px; height: 34px; }
.connectors button.enabledatesubmitbtn:hover{right:35px;background-color: #C09507 !important;}
.connectors .supportcardsecondclosedcolumndata {text-align: right; margin-right: 9px; margin-top: 20px;}
.connectors .supportcardfromdate .MuiTextField-root{background: #F1F1F1;}
.connectors .supportcardtodate .MuiTextField-root{background: #F1F1F1;}
.connectors .supportfirstmaindiv{margin-left: 70px;margin-right: 70px;background: #FCFCFC;}
.connectors .supportViewDetailsback{margin-left: 14px;font-weight: 400;font-size: 14px; color: #3491EE;}
.connectors .supportViewDetailsbackimage{margin-left: 94px;margin-top: 54px;text-align: left;cursor: pointer;}
.connectors .supportViewDeatilsSecondRow{border: 1px solid rgba(238, 238, 238, 0.5);margin-top:23px;}
.connectors .cardConnectorName{font-size:14px;font-weight:600;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 50px;margin-top: 20px;text-align: left;}
.connectors .oversizemb-uploadimglogo {
    width: 100%;
    color: red;
  }
.connectors .marginrowtop8px{margin-top:8px}
/* .resolution textarea{border: none;
    border-bottom: 1px solid black;
    background: #F1F1F1;
    border-radius: 2px;
} */
.connectors .messagedescription{
height: 117px;
background: #FFFFFF;
border: 1px solid #F9EABC;
border-radius: 2px;
overflow: scroll;
width: 306px;
padding: 10px;
}
.connectors .nodataavailable{
  margin-left: 50%;
  margin-top: 10%;
}
