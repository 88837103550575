.intro-image {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    top: 278px;
    /*top: 255px;*/
    /* width: 50px;
    height: 50px; */
    border-radius: 50%;
    margin-top: 1%;
  
    background: #f8f8f8;
  }
  .leftheader {
    position: absolute;
    width: 308px;
    height: 27px;
    left: 182px;
    top: 287px;
    /*top: 265px;*/
    text-align: start;
    /*padding-left: 18px;*/
  
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27.24px;
  
    color: #c09507;
  }
  .leftcontent {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 320px;
    /*top: 300px;*/
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21.79px;
    text-align: left;
    color: #3d4a52;
  }
  .intro-image2 {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    /* top: 432px; */
    top: 418px;
    border-radius: 50%;
    background: #f8f8f8;
  }
  .leftheader2 {
    position: absolute;
    width: 418px;
    height: 27px;
    left: 182px;
    top: 427px;
    /*top: 402px;*/
    text-align: start;
    /*padding-left: 18px;*/
  
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  
    color: #c09507;
  }
  .leftcontent2 {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 460px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3d4a52;
    text-align: left;
  }
  
  .leftheader3 {
    position: absolute;
    width: 418px;
    height: 27px;
    left: 182px;
    top: 567px;
    text-align: start;
    /*padding-left: 18px;*/
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
  
    color: #c09507;
  }
  .intro-image3 {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    top: 558px;
    border-radius: 50%;
    background: #f8f8f8;
  }
  .leftcontent3 {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 600px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3d4a52;
    text-align: left;
  }
  
  .leftintroparticipant .intro-image4 {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 70px;
    top: 683px;
    border-radius: 50%;
    background: #f8f8f8;
  }
  .leftintroparticipant .leftheader4 {
    position: absolute;
    width: 418px;
    height: 27px;
    left: 182px;
    top: 692px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: start;
    color: #c09507;
  }
  .leftintroparticipant .leftcontent4 {
    position: absolute;
    width: 528px;
    height: 44px;
    left: 182px;
    top: 725px;
    /*top: 720px;*/
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 73px;
    color: #3d4a52;
    text-align: left;
  }
  