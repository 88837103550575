.browse_style {
    width: 468px;
}
.imports_style{
    max-width: fit-content;
    margin-left: 15px;
}

.list_files {
    width: 370px;
}

.list_upload_style {
    margin-left: 30px;
    max-height: 500px;
    overflow: auto; 
}