.datasetform {
  overflow-x: hidden;
}

.datasetform .AddDatasetmainheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}
.datasetform .name {
  width: 100%;
  margin-top: 5%;
  /*margin-left: 40%;*/
}
.datasetform .description {
  float: left;
  width: 100% !important;
  margin-top: 5%;
  /*margin-left: 3%;*/
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.datasetform .checkbox1 {
  width: 100%;
  margin-left: 0px;
  margin-top: 1%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  /* headings */

  color: #3d4a52;
}
.datasetform .AddDatasetsecondaryheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  /*margin-left: 20%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}
.datasetform .checkbox2 {
  width: 100%;
  /*margin-left: -40px;*/
  margin-top: 1%;
  font-family: "Open Sans";
  float: left;
}
.datasetform .checkbox2 .soil {
  /*float: left;*/
  font-family: "Open Sans";
}
.datasetform .checkbox2 .weather {
  /*float: left;*/
  font-family: "Open Sans";
  /*margin-left: -18px;*/
}
.datasetform .crop {
  width: 100% !important;
  margin-top: 10%;
  /*margin-left: 3%;*/
  float: left;
}
.datasetform .AddDatasetageheading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  float: left;
  /*margin-left: 41%;*/
  margin-top: 3%;
  margin-bottom: 3%;
}
.datasetform .constantswitch {
  margin-top: 6%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3d4a52;
  margin-right: -10px;
  float: right;
}
.datasetform .radiobtns {
  /*margin-left: 20%;*/
}
.datasetform .radiobtns .sixmonth {
  margin-left: 13%;
}
.datasetform .radiobtns .ninemonth {
  margin-left: 13%;
}
.datasetform .radiobtns .twelvemonth {
  margin-left: 13%;
}
.datasetform .FromDate {
  /*padding-left: 21%;
  padding-top: 2%;*/
}

.datasetform .toDate {
  /*padding-top: 2%;
  /* float: left; */
}

.datasetform .FromDate .fromtextfield {
  width: 100%;
}
.datasetform .toDate .totextfield {
  float: left;
  width: 100%;
  /* padding-left: 2%; */
  /*margin-left: 3%;*/
}
.datasetform .recordradiobtns {
  /*margin-left: 20%;*/
  /*margin-top: 1%;*/
}

.datasetform .recordradiobtns .record2 {
  margin-left: 16.5%;
}
.datasetform .recordradiobtns .record3 {
  margin-left: 16.5%;
}
.datasetform .recordradiobtns .record4 {
  margin-left: 16.5%;
}
.datasetform .notavaiable {
  margin-left: 13%;
}
.datasetform .private {
  margin-left: 15%
}
.datasetupload {
  background: #ffffff;
  border: 1px dashed #d8af28;
  border-radius: 2px;
  margin-top: 0;
  /*margin-left: 35%;*/
  /*width: 220%;*/
  height: 218px;
  align-items: center;
}
.uploaddatasetname {
  text-align: center;
  width: 100%;
}

.fileUpload {
  width: 100% !important;
  float: left !important;
}
.dataset-description-in-dataset-details{
  all: unset;
}

.dataset-description-in-dataset-details p{
  display: inline-block;
  overflow: hidden;
  max-height: 60px;
  text-overflow: ellipsis;
  width: 300px;
} 
.cardheaderTitlespecifier span{
  display: inline-block;
  overflow: hidden;
  max-height: 60px;
  text-overflow: ellipsis;
  width: 250px;
}
